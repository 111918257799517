.MuiInputAdornment-root {
  position: absolute;
  right: 6px;
  span {
    margin: 0;
    svg {
      font-size: 22px;
      color: #a3a3a3;
    }
  }
  .MuiButtonBase-root {
    padding: 0;
  }
}
.evet-select-wrapper {
  position: relative;
  font-size: 12px;
  user-select: none;
  border-radius: 6px;
  min-width: 120px;
  span {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #777777;
    display: block;
  }
  .evet-select-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 6px;
    padding: 8px 0;
    cursor: pointer;
    background: #f9f9f9;
    &.opened {
      border-bottom: none;
      border-radius: 6px 6px 0 0;
    }
    svg {
      font-size: 22px;
      color: #535353;
    }
    span {
      margin-right: 20px;
    }

    .evet-select-label {
      margin: 0 10px;
      font-weight: 300;
    }
  }

  .evet-select-list {
    position: absolute;
    z-index: 98;
    width: 100%;
    //max-height: 3660px; we dont need it
    background: #fff;
    border: 1px solid #e3e3e3;
    border-top: none;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    font-weight: 700;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    ::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $primary-color;
    }

    .evet-select-scroll-list {
      //overflow: hidden;
      padding: 0;
      .evet-select-list-item {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
        padding: 4px 10px;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        border-bottom: 1px solid #e3e3e3;
        &:last-child {
          border-bottom: none !important;
          border-radius: 0 0 4px 4px;
        }
        input {
          &.hide {
            display: none;
          }
        }
        input[type='checkbox'] {
          width: 14px !important;
          height: 14px !important;
          margin: 5px;
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
          box-shadow: none;
          font-size: 0.8em;
          text-align: center;
          line-height: 1em;
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 2px;
          &:checked {
            background: $primary-color;
            border: 1px solid $primary-color;
          }
        }

        input[type='checkbox']:checked:after {
          content: '✔';
          color: #fff;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          text-transform: capitalize;
        }

        &.no-result {
          font-weight: normal;
          cursor: default;
        }

        &:hover {
          background-color: #efefef;
          color: black;
        }

        &.selected {
          background: #e3e3e3;
          border-bottom: 1px solid #efefef;
          span {
            color: #000;
            font-weight: 500;
          }

          &:hover {
            background-color: #efefef;
            color: palegreen;
          }
        }
      }
    }

    .evet-select-list-search-bar {
      width: 100%;
      border-top: none;
      border-right: none;
      border-left: none;
      font-size: inherit;
      border-radius: 0 !important;
      -webkit-box-shadow: 0 6px 12px -9px rgba(0, 0, 0, 0.45);
      -moz-box-shadow: 0 6px 12px -9px rgba(0, 0, 0, 0.45);
      box-shadow: 0 6px 12px -9px rgba(0, 0, 0, 0.45);
      label {
        margin: 0 !important;
        input {
          border: none;
          &::placeholder {
            color: #797979;
            font-size: 12px;
          }
        }
      }
    }
    &.all-hide {
      display: none;
    }
    &.always-open {
      z-index: 0;
      position: relative;
      border-radius: 6px;
      border-top: 1px solid #e3e3e3;
      .evet-select-list-search-bar {
      }
    }
    &.relative {
      position: relative;
    }
    &.searchable {
      overflow-y: hidden;
      padding: 0;
    }
  }
}

.evet-select-wrapper {
  position: relative;
  font-size: 12px;
  user-select: none;
  border-radius: 6px;
  min-width: 120px;
  span {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #777777;
    display: block;
  }
  .evet-select-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 6px;
    padding: 8px 0;
    cursor: pointer;
    background: #f9f9f9;
    &.opened {
      border-bottom: none;
      border-radius: 6px 6px 0 0;
    }
    svg {
      font-size: 22px;
      color: #535353;
    }
    span {
      margin-right: 20px;
    }

    .evet-select-label {
      margin: 0 10px;
      font-weight: 300;
    }
  }

  .evet-select-list {
    position: absolute;
    z-index: 98;
    width: 100%;
    //max-height: 3660px; we dont need it
    background: #fff;
    border: 1px solid #e3e3e3;
    border-top: none;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    font-weight: 700;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    ::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $primary-color;
    }

    .evet-select-scroll-list {
      //overflow: hidden;
      padding: 0;
      .evet-select-list-item {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
        padding: 4px 10px;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        border-bottom: 1px solid #e3e3e3;
        &:last-child {
          border-bottom: none !important;
          border-radius: 0 0 4px 4px;
        }
        input {
          &.hide {
            display: none;
          }
        }
        input[type='checkbox'] {
          width: 14px !important;
          height: 14px !important;
          margin: 5px;
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
          box-shadow: none;
          font-size: 0.8em;
          text-align: center;
          line-height: 1em;
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 2px;
          &:checked {
            background: $primary-color;
            border: 1px solid $primary-color;
          }
        }

        input[type='checkbox']:checked:after {
          content: '✔';
          color: #fff;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          text-transform: capitalize;
        }

        &.no-result {
          font-weight: normal;
          cursor: default;
        }

        &:hover {
          background-color: #efefef;
          color: black;
        }

        &.selected {
          background: #e3e3e3;
          border-bottom: 1px solid #efefef;
          span {
            color: #000;
            font-weight: 500;
          }

          &:hover {
            background-color: #efefef;
            color: palegreen;
          }
        }
      }
    }

    .evet-select-list-search-bar {
      width: 100%;
      border-top: none;
      border-right: none;
      border-left: none;
      font-size: inherit;
      border-radius: 0 !important;
      -webkit-box-shadow: 0 6px 12px -9px rgba(0, 0, 0, 0.45);
      -moz-box-shadow: 0 6px 12px -9px rgba(0, 0, 0, 0.45);
      box-shadow: 0 6px 12px -9px rgba(0, 0, 0, 0.45);
      label {
        margin: 0 !important;
        input {
          border: none;
          &::placeholder {
            color: #797979;
            font-size: 12px;
          }
        }
      }
    }
    &.all-hide {
      display: none;
    }
    &.always-open {
      z-index: 0;
      position: relative;
      border-radius: 6px;
      border-top: 1px solid #e3e3e3;
      .evet-select-list-search-bar {
      }
    }
    &.relative {
      position: relative;
    }
    &.searchable {
      overflow-y: hidden;
      padding: 0;
    }
  }
}
