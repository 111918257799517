.extension-referral-modal {
  padding: 15px;

  .label {
    font-size: 16px !important;
  }

  .value {
    font-size: 14px !important;
  }
}
