.user-profile-modal {
  width: 1100px;

  .tab-menu {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: red;
    height: auto;

    li {
      display: flex;
      align-items: center;
      background: #dfdfdf;
      list-style-type: none;
      padding: 14px 10px;
      font-size: 14px;
      cursor: pointer;
      outline: none;
      border-right: 1px solid #ccc;
      width: calc(100% / 3);

      &:hover {
        background: #efefef;
      }

      &:first-child {
        border-radius: 6px 0 0 0;
      }

      &:last-child {
        border-radius: 0 6px 0 0;
        border: none;
      }

      &.react-tabs__tab--selected {
        background: #fff;
      }

      svg {
        margin-right: 10px;
      }
    }

    &.switch-style {
      display: flex;
      justify-content: flex-start;

      &.item {
        background: transparent !important;
      }

      li {
        padding: 4px 10px;
        background: transparent;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid #fff;

        span {
          color: $grey75;
          font-size: 14px;
          font-weight: 500;

          p {
            color: #838383;
            font-size: 14px;
            font-weight: 400;
            background: transparent !important;
          }
        }

        &:nth-child(1) {
          &.react-tabs__tab--selected {
            border: 1px solid $green;

            span {
              color: #000;

              p {
                color: $green;
              }
            }
          }
        }

        &:nth-child(2) {
          &.react-tabs__tab--selected {
            border: 1px solid $alert-color;

            span {
              color: #000;

              p {
                color: $alert-color;
              }
            }
          }
        }
      }
    }
  }

  .base {
    background: #fff !important;
    border-radius: 0;
    position: relative;
    z-index: 999;

    .modal-scroll-body {
      min-height: auto;
      max-height: 200px !important;
    }

    .list-table-modal {
      border-radius: 6px;
      min-height: 100% !important;

      tbody {
        tr {
          &:last-child {
            border: none;
          }

          td {
            padding: 2px 8px 2px 8px !important;
            font-weight: 400;
            font-size: 12px;
            position: relative;
            height: 24px;

            &:first-child {
              font-weight: 500;
              color: #535353;
            }
          }
        }
      }
    }

    .item {
      background: #f6f6f6;
      border-radius: 6px;
      padding: 0 !important;
      position: relative;

      .icon {
        min-height: 100% !important;
        border-radius: 6px 0 0 6px;
        border-right: 1px solid #fff;
        background: transparent;
        width: 50px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        svg {
          font-size: 30px;
          color: #9c9c9c;
        }
      }

      span {
        font-size: 22px;

        p {
          font-size: 12px;
          font-weight: 600;
          background: #000;
          color: #fff;
          padding: 3px;
          border-radius: 2px;
          margin-right: 4px;
        }

        &.affiliated {
          p {
            background: $green;
          }
        }

        &.non-affiliated {
          margin-left: 20px;

          p {
            background: $alert-color;
          }
        }
      }

      button {
        position: absolute;
        border: none;
        right: 0;
        height: 100%;
        background: #efefef;
        padding: 0 20px;
        border-radius: 0 6px 6px 0;
        color: $link-color;
        cursor: pointer;

        &:hover {
          background: var(--primary-color);
          color: #fff;
        }
      }

      &:hover {
        .icon {
          svg {
            color: #000;
          }
        }
      }
    }
  }
}
