.profile {
  border-left: 1px solid $border-purple;
  min-width: 140px;
  cursor: pointer;
  position: relative;

  .profile-button {
    svg {
      position: absolute;
      color: #fff;
      right: 10px;
    }
  }

  .profile-dropdown {
    display: block;
    position: absolute;
    top: 50px;
    right: 0;
    width: auto;
    border-radius: 0 0 0 10px;
    background: #fff;
    z-index: 500;
    overflow: hidden;

    a {
      color: $grey75;
      padding: 12px 50px 12px 10px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      svg {
        color: $grey75;
        margin-right: 13px;
        width: 20px;
        height: 20px;
        font-size: 12px !important;
      }

      &:hover {
        background: $extra-light-prurpe;
      }
    }
  }
}
