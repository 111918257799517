@import 'equalrate';

.dropdown {
  label {
    min-width: 100% !important;
  }
  .dropdown-menu {
    button {
      border: none;
      background: transparent;
      white-space: nowrap;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 10px 20px 10px 10px;
      font-size: 14px;
      text-decoration: none;
      box-sizing: border-box;
      color: #000;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      svg {
        margin-right: 10px;
        font-size: 14px;
        color: #000;
      }
    }
  }
}
.animation {
  transition: all 400ms ease-out;
}

.show {
  display: flex !important;
}

.hide {
  display: none !important;
}

.scrollable-base {
  max-height: 300px;
  min-height: 200px;
  overflow-x: auto;
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100% !important;
  background: #f9f9f9;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

#root {
  width: 100%;

  &.dark-mode {
    background: yellow;
  }
}

.btn-loader {
  border: 2px solid #fff;
  border-radius: 50%;
  border-top: 2px solid $primary-color;
  border-bottom: 2px solid $primary-color;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.container {
  width: 1200px;
}

.section-title {
  h3 {
    color: rgb(58, 58, 58);
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .filter {
  }
}

.react-confirm-alert-button-group {
  button {
    padding: 12px !important;
    background: var(--primary-color) !important;
    cursor: pointer !important;
    font-size: 14px !important;
    outline: none !important;
    transition: all 0.4s !important;
    border-radius: 4px !important;
    width: auto !important;
    margin-right: 6px !important;

    &:nth-child(1) {
      background: #fff !important;
      color: $grey9a !important;
      border: 1px solid $grey9a !important;
    }
  }
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
}

.react-confirm-alert-body {
  padding: 10px !important;
}

#round1 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  -webkit-animation: round1-rotate 2s infinite linear;
  animation: round1-rotate 2s infinite linear;
}

#round1 span {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  background-color: var(--primary-color);
  border-radius: 50%;
  -webkit-animation: round1-bounce 2s infinite ease-in-out;
  animation: round1-bounce 2s infinite ease-in-out;
}

#round1 span:nth-child(2) {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes round1-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round1-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes round1-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes round1-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.relative {
}

.modal-form {
  @keyframes smooth-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.open {
    display: flex;
    height: 100%;
    animation: smooth-show 1s;
  }

  &.close {
    display: none;
    opacity: 0;
  }

  display: flex;
  align-items: center !important;
  justify-content: center;
  position: fixed;
  width: 100%;
  overflow-x: auto;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 60;

  &-container {
    &.website-container {
      min-width: 860px;
      max-width: 600px;
    }

    &.payment-container {
      min-width: 700px;
      max-width: 600px;
    }

    &.user-container {
      min-width: 700px;
      max-width: 600px;
    }

    box-sizing: border-box;
    background: #fff;

    .modal-form-title {
      border-bottom: 1px solid #ccc !important;
      padding: 10px;
      background: #f9f9f9;

      h3 {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.opened-menu {
  background: rgba(0, 0, 0, 0.1);
}

.json-editor {
  height: 290px;
  overflow-x: auto;
  background: #272922;
  position: relative;

  .variable-row {
    word-wrap: break-word;
    position: relative;
    box-sizing: border-box;

    .variable-value {
      position: relative;
      word-wrap: break-word;
      width: 100%;
      box-sizing: border-box;

      :first-child {
        width: 100%;
        box-sizing: border-box;
      }

      .string-value {
        word-wrap: break-word;
        position: relative;
        display: inline;
        width: 100px !important;
        box-sizing: border-box;
      }
    }
  }
}

.summary-card-base {
  .item {
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    svg {
      font-size: 28px;
      margin-right: 10px;
      color: var(--primary-color);
    }
    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &.active {
      background-color: #f9f9f9;

      &:hover {
        border: 1px solid #e3e3e3;

        button {
          display: none;
        }
      }
    }

    &:hover {
      border: 1px solid var(--primary-color);

      button {
        display: block;
      }
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: #7d8388;
    }

    p {
      font-size: 28px;
      font-weight: 300;
      padding-top: 6px;
    }

    button {
      display: none;
      position: absolute;
      right: 20px;
      color: $link-color;
    }
  }

  .chart-base {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}
