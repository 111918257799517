.success-count {
  .item {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding: 6px 10px 0 0 !important;
    margin: 0 !important;
    border: none !important;

    .count {
      padding: 0 8px !important;
      margin: 0 !important;
      border: 1px solid #efefef;
      border-left: none;
      font-size: 12px !important;
      border-radius: 0 4px 4px 0;
    }

    .icon {
      background: #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      margin: 0 0 0 0 !important;
      border-radius: 4px 0 0 4px;

      &.yes {
        svg {
          color: $green;
        }
      }

      &.no {
        svg {
          color: $alert-color;
        }
      }
    }
  }
}

.usage-list {
  width: 1000px;
}
