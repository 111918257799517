.project-menu {
  button {
    border: none;
    background: transparent;
    color: #fff;
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    p {
      font-size: 22px;
      font-weight: 300;
    }

    svg {
      padding-left: 10px;
      font-size: 26px;
    }
  }

  > .dropdown {
    position: absolute;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 0 6px 0 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 40;
    padding: 20px;
    .close-icon {
      cursor: pointer;
      padding: 4px;
      height: auto;
    }
    .item {
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #e3e3e3;
      &:last-child {
        border-bottom: none;
      }
      .media {
        padding-right: 20px;
        svg {
          font-size: 26px;
        }
      }
      .detail {
        width: 200px;
        p {
          font-size: 16px;
          font-weight: 600;
        }
        span {
          font-size: 12px;
          display: block;
          font-weight: 400;
          line-height: 16px !important;
          color: #888888;
        }
      }
      button {
        background: $primary-color;
        color: #fff;
        margin-top: 10px;
        border-radius: 4px;
        padding: 4px;
        font-size: 12px;
        cursor: pointer;
      }

      &.active {
        background: #f9f9f9;
        button {
          display: none;
        }
      }
    }
  }
}
