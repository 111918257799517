.headline {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.open {
    background: #e3e3e3;
  }

  .breadcrumb {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;

    span,
    a,
    button {
      display: flex;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      color: #757575;
      font-weight: 500;
      padding-right: 10px;

      svg {
        margin-right: 4px;
      }
    }
  }

  .options {
    .item {
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #efefef;

      &:last-child {
        border: none;
        padding: 0;
        margin: 0;
      }

      p {
        font-size: 12px;
        color: #6b6b6b;
        font-weight: 700;
      }

      span {
        font-size: 20px;
        font-weight: 300;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 6px;

        svg {
          margin-right: 10px;
          color: #6b6b6b;
        }
      }
    }
  }
}
