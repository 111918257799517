.switch-url {
  border-left: 1px solid $border-purple;
  min-width: 240px;
  padding-right: 40px;
  box-sizing: border-box;
  position: relative;

  .selected-url {
    cursor: pointer;
    width: 100%;

    svg {
      position: absolute;
      color: #fff;
      right: 10px;
    }

    .item {
      p {
        color: #fff;
      }

      span {
        color: $light-purple;
      }
    }
  }

  .url-list {
    width: 250px;
    display: none;
    position: absolute;
    background: #fff;
    top: 50px;
    z-index: 500;

    .pending-message {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      background: $yellow;
      width: 100%;
      height: 37px;
      font-size: 12px;
      align-items: center;
      justify-content: flex-start;
      padding-left: 10px;
      box-sizing: border-box;

      &.show {
        display: flex;
      }
    }

    .search {
      height: 30px;

      input {
        height: 30px;
      }

      svg {
        position: absolute;
        right: 10px;
        color: #a6a6a6;
      }

      input {
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #e3e3e3;
        padding: 10px 10px 10px 10px;
      }
    }

    .scroll-base {
      overflow-y: auto;
      max-height: 280px;

      .item {
        padding: 10px 10px 10px 10px;
        border-bottom: 1px solid #e3e3e3;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;

        &.disabled {
          background: #f9f9f9;

          p {
            color: #acacac;

            span {
              color: #acacac;
            }
          }
        }

        &:hover {
          background: $extra-light-prurpe;
        }

        p {
          color: $grey;
          font-weight: 400;
        }

        span {
          color: $grey75;
        }

        .statusactive {
          width: 8px;
          height: 8px;
          background: $green;
          position: absolute;
          right: 4px;
          top: 14px;
          border-radius: 50%;
        }

        .statuspending {
          width: 8px;
          height: 8px;
          background: $yellow;
          position: absolute;
          right: 4px;
          top: 14px;
          border-radius: 50%;
        }
      }
    }
  }
}
