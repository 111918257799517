.login {
  position: absolute;
  width: 100%;
  background: var(--primary-color);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    overflow: hidden;

    .login-image {
      width: 250px;
      background: url('../../assets/images/login-bg.jpg') center;
      background-size: cover;
      position: relative;

      .logo {
        padding: 10px;
        width: 100px;

        p {
          color: var(--primary-color);
          position: absolute;
          bottom: 14px;
          left: 14px;
          font-size: 14px;
          font-weight: 600;
          padding: 4px;

          img {
            width: 140px;
            margin-top: 10px;
          }
        }
      }
    }

    .form {
      .login-card {
        width: 400px;
        padding: 40px;
        box-sizing: border-box;

        h2 {
          span {
            font-weight: 500;
            padding-left: 6px;
            font-size: 16px;
            color: var(--primary-color);
          }
        }
      }
    }
  }
}
