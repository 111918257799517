.check-mark {
  border: 1px solid #ccc;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  svg {
    display: none;
  }
  &.selected {
    border: 1px solid var(--primary-color);
    svg {
      display: block;
      line-height: 0;
      color: var(--primary-color);
    }
  }
}
