.overflow-hidden {
  overflow-y: hidden;
}

//label {
//  display: flex;
//  flex-direction: row;
//  justify-content: flex-start;
//  align-items: center;
//
//  span {
//    font-size: 12px;
//    font-weight: normal;
//    font-stretch: normal;
//    font-style: normal;
//    text-align: left;
//    color: #777777;
//  }
//
//  svg {
//    position: absolute;
//    color: #9a9a9a;
//    right: 0;
//  }
//
//  input[type='text'],
//  input[type='password'],
//  input[type='email'],
//  input[type='tel'],
//  input[type='number'],
//  input[type='date'],
//  textarea {
//    width: 100%;
//    padding: 16px 40px 16px 0;
//    border: none;
//    border-bottom: 1px solid #ccc;
//    outline: none;
//    background: none;
//    height: 10px;
//    transition: all 1s;
//    font-size: 14px;
//    overflow: hidden;
//    color: #000;
//    -webkit-box-sizing: border-box;
//    -moz-box-sizing: border-box;
//    box-sizing: border-box;
//
//    &:focus {
//      border-bottom: 1px solid var(--primary-color);
//    }
//
//    &::-webkit-inner-spin-button {
//      display: none;
//    }
//
//    &::-webkit-clear-button {
//      display: none;
//      -webkit-appearance: none;
//    }
//
//    &::-webkit-calendar-picker-indicator {
//      opacity: 0;
//      position: absolute;
//      right: 10px;
//      bottom: 25px;
//      z-index: 99;
//      width: 14px;
//      height: 20px;
//      cursor: pointer;
//    }
//  }
//
//  textarea {
//    padding: 8px;
//    height: 60px;
//  }
//
//  .select-box {
//    select {
//      padding: 8px 40px 8px 0;
//      border: none;
//      background: none;
//      border-bottom: 1px solid #cccccc;
//      width: 100%;
//      box-sizing: border-box;
//      outline: none;
//      font-size: 14px;
//      overflow: hidden;
//      border-radius: 0;
//      -webkit-appearance: none;
//      color: $grey75;
//      font-weight: 400;
//
//      option {
//        font-weight: 500;
//      }
//    }
//
//    position: relative;
//    width: 100%;
//  }
//
//  &.error {
//    span {
//      color: red;
//    }
//
//    input {
//      border-bottom: 2px solid red !important;
//      color: red;
//    }
//  }
//
//  .check-box {
//    display: flex;
//    flex-direction: row;
//    justify-content: flex-start;
//    align-items: center;
//    font-size: 14px;
//
//    input {
//      margin-right: 10px;
//      -webkit-appearance: none;
//      border: 1px solid #ccc;
//      width: 14px;
//      height: 14px;
//      border-radius: 50%;
//
//      &:checked {
//        background: var(--primary-color);
//      }
//    }
//  }
//
//  &.date-picker {
//    width: auto;
//    position: relative;
//
//    svg {
//      position: absolute;
//      right: 10px;
//      z-index: 40;
//    }
//
//    .first {
//      width: 140px;
//      border: none;
//      border-left: 1px solid #ccc;
//      border-top: 1px solid #ccc;
//      border-bottom: 1px solid #ccc;
//    }
//
//    .last {
//      width: 160px;
//      border: none;
//      border-right: 1px solid #ccc;
//      border-top: 1px solid #ccc;
//      border-bottom: 1px solid #ccc;
//    }
//
//    input:focus {
//      border: 1px solid var(--primary-color);
//    }
//  }
//}

.tag-input {
  width: 100%;

  .added-input {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;

      svg {
        position: absolute;
      }

      input {
        width: 100%;
        padding: 16px 40px 16px 0;
        border: none;
        border-bottom: 1px solid #ccc;
        outline: none;
        background: none;
        height: 10px;
        transition: all 1s;
        font-size: 14px;
        overflow: hidden;
        color: #000;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        &::-webkit-inner-spin-button {
          display: none;
        }

        &::-webkit-clear-button {
          display: none;
          -webkit-appearance: none;
        }

        &::-webkit-calendar-picker-indicator {
          opacity: 0;
          position: absolute;
          right: 10px;
          bottom: 25px;
          z-index: 99;
          width: 14px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .tag-limit {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 12px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
      position: relative;
      padding: 4px;
      margin: 10px 4px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      background: #fff;
      color: $grey75;
      border: 1px solid #ccc;

      svg {
        position: relative;
        font-size: 12px;
        margin: 0;
        right: 0;
        margin-left: 10px;
        padding: 0;
        color: #ccc;
        cursor: pointer;
      }
    }
  }

  input {
    width: 100%;
    margin: 0 !important;
  }
}

.quick-tip {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  svg {
    position: relative;
    right: auto;
    font-size: 18px;
    color: #9a9a9a;
  }
  &.white {
    svg {
      color: #fff;
    }
  }

  .tips {
    position: absolute;
    padding: 10px;
    width: 200px;
    background: #fff;
    font-size: 14px;
    line-height: 18px;
    color: $grey;
    margin: -10px 0 10px 10px;
    border-radius: 2px;
    z-index: 50;
    word-wrap: break-word;
  }
}

.user-id-button {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  .icon {
    background: #efefef;
    padding: 2px 4px 2px 4px;

    svg {
      color: var(--primary-color) !important;
      font-size: 12px;
    }
  }

  p {
    padding: 2px 4px 2px 4px;
    z-index: auto;
  }
}

.btn {
  padding: 12px;
  background: var(--primary-color);
  cursor: pointer;
  font-size: 14px;
  outline: none;
  transition: all 0.4s;
  width: auto;
  border-radius: 4px;

  &.small {
    padding: 4px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active {
    background: var(--primary-color);
    color: #fff;
  }

  &.submit {
    background: var(--primary-color);
    color: #fff;
    font-size: 16px;
    display: inline-block;
    border: none;

    &:hover {
      background: #3a3a3a;
    }
  }

  &.primary {
    background: var(--primary-color);
    color: #fff;
    border: 1px solid var(--primary-color);
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 6px;
    }

    &.submit-loader {
      background: linear-gradient(
        132deg,
        #42907b,
        #b1a9a2,
        #46b3cb,
        #268f99,
        #9a79a0,
        #57d2c2
      );
      background-size: 400% 400%;
      animation: pulsecolor 2s infinite;
      border: 1px solid #fff;
    }

    &:hover {
      border: 1px solid var(--primary-color);
      background: #fff;
      color: var(--primary-color);
    }
  }

  &.secondary {
    background: #fff;
    color: $grey9a;
    border: 1px solid $grey9a;

    &:hover {
      border: 1px solid var(--primary-color);
      background: #fff;
      color: var(--primary-color);
    }
  }

  &.check {
    background: $green;
    color: #fff;
    border: 1px solid $green;

    &:hover {
      border: 1px solid $green;
      background: #fff;
      color: $green;
    }
  }

  &.add-button {
    background: none;
    border: 1px solid $green;
    color: $green;

    svg {
      font-size: 16px;
      margin-right: 4px;
      font-weight: 600;
    }

    span {
      font-size: 12px !important;
      font-weight: 600 !important;
      margin: 0 !important;
    }

    &:hover {
      background: $green;

      svg,
      span {
        color: #fff;
      }
    }
  }

  &.basic {
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: $link-color;
    padding: 0;

    &.red {
      color: $alert-color;
    }

    svg {
      margin-right: 4px;
    }

    &:hover {
      border: none;
    }
  }

  &:disabled {
    background: #e3e3e3;
    color: #8a8a8a;
    border: 1px solid #ccc;

    &:hover {
      background: #e3e3e3;
      color: #8a8a8a;
      border: 1px solid #ccc;
    }
  }

  @keyframes pulsecolor {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
}

.modal-base {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;

  &.show {
    display: flex !important;
  }

  .modal {
    background: #fff;
    min-width: 400px;
    max-width: 12000px;
    -webkit-box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.12);
    animation-name: box-open;
    border-radius: 6px !important;
    overflow: hidden;
    animation-duration: 1000ms;
    @keyframes box-open {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    &.small-size {
      width: 500px;
    }

    &.medium-size {
      width: 700px;
    }

    &.large-size {
      width: 1000px;
    }

    .modal-title {
      padding: 10px;
      border-bottom: 1px solid #e3e3e3;
      background: #f9f9f9;

      h2 {
        font-size: 16px;
        font-weight: 400;
      }

      svg {
        font-size: 22px;
        color: $grey9a;
        cursor: pointer;
      }
    }

    .modal-content {
      box-sizing: border-box;

      .modal-scroll-body {
        min-height: auto;
        max-height: 360px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .modal-action {
    }
  }

  &.right {
    display: flex;
    justify-content: flex-end;
    min-height: 100% !important;

    .modal {
      min-width: 700px !important;
      height: 100% !important;
      overflow-y: auto;
      transition: margin 300ms ease-out;
      position: relative;
      z-index: 9999 !important;

      .modal-title {
        padding: 20px 10px 20px 10px;
        border-bottom: 1px solid #e3e3e3;

        h2 {
          font-size: 22px;
          font-weight: 400;
        }

        svg {
          font-size: 22px;
          color: $grey9a;
          cursor: pointer;
          padding: 0 20px 0 10px;
        }
      }

      .modal-content {
        box-sizing: border-box;
        height: auto !important;
        overflow-y: scroll;
      }
    }

    &.show {
      right: 0;

      .modal {
        margin-right: 1px;
      }
    }
  }

  &.empty {
    .modal {
      //background: transparent;
      min-width: 400px;
      max-width: 12000px;
      -webkit-box-shadow: none;
      background: #fff;
      -moz-box-shadow: none;
      box-shadow: none;
      animation-name: box-open;
      border-radius: 6px !important;
      overflow: hidden;
      animation-duration: 1000ms;
      position: relative;
      @keyframes box-open {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      .close-icon {
        font-size: 22px;
        color: $grey9a;
        cursor: pointer;
        background: #fff;
        border-radius: 0 0 6px 6px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #efefef;

        button {
          padding: 7px;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          width: 100px;
          color: #535353;

          svg {
            margin-right: 4px;
            font-size: 12px;
          }

          &:hover {
            background: #efefef;
            color: $link-color;
          }
        }
      }

      .modal-title {
        padding: 10px;
        border-bottom: 1px solid #e3e3e3;
        background: #f9f9f9;

        h2 {
          font-size: 16px;
          font-weight: 400;
        }

        svg {
          font-size: 22px;
          color: $grey9a;
          cursor: pointer;
        }
      }

      .modal-content {
        box-sizing: border-box;

        .modal-scroll-body {
          min-height: auto;
          max-height: 300px;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }

      .modal-action {
      }
    }
  }
}

.ad-detail-table {
  width: 100%;
  overflow-y: scroll;
  height: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    tr {
      td {
        box-sizing: border-box;
        padding: 10px;
        border-bottom: 1px solid #e3e3e3;

        &:nth-child(1) {
          background: $extra-light-prurpe;
          width: 30% !important;
        }

        &:nth-child(2) {
          background: #fff;
          width: 70%;
        }
      }
    }
  }
}

.status {
  &.active {
    color: $green;
  }

  &.pending {
    color: $yellow;
  }

  &.inactive {
    color: $alert-color !important;
  }

  &.cancelled {
    color: lightgrey;
  }

  &.failed {
    color: $alert-color;
  }

  &.no_subscription {
    color: $green;
  }
}

.removed-row {
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: flex-start;

  .message {
    background: $alert-color;
    padding: 4px;
    position: absolute;
    border-radius: 10px 0 0 10px;
    left: -15px;
    color: #fff;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.list-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    background: #fff;

    tr {
      border-bottom: 1px solid #e3e3e3;

      td {
        padding: 8px;
        font-weight: 500;
        font-size: 12px;
        height: 30px;

        label {
          svg {
            position: relative;
            color: #000;
            cursor: pointer;
          }
        }
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: rgba(var(--primary-color-rgb), 0.04);
    }

    tr:nth-child(even) {
      background: #fff;
    }

    tr {
      border-bottom: 1px solid #e3e3e3;

      &:hover {
        background: #f9f9f9;
      }

      &.passive {
        color: #9a9a9a !important;
      }

      td {
        padding: 0 8px 0 8px;
        font-weight: 400;
        font-size: 12px;
        position: relative;
        height: 40px;

        .screenshot-btn {
          border: 1px solid #e3e3e3;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 50px;
          border-radius: 10px;
          background: #fff;
          cursor: pointer;
          color: #7f37be;

          &:hover {
            background: $link-color;
            color: #fff;
            border: 1px solid $link-color;
          }

          svg {
            margin-right: 4px;
          }
        }

        .ad-detail-btn {
          font-size: 12px;
          border: 1px solid #e3e3e3;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: #fff;
          cursor: pointer;
          color: #7f37be;
          width: auto;

          &:hover {
            background: $link-color;
            color: #fff;
            border: 1px solid $link-color;
          }

          svg {
            margin-right: 4px;
          }
        }

        .reactivate-btn {
          font-size: 12px;
          border: 1px solid var(--primary-color);
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: var(--primary-color);
          cursor: pointer;
          color: #fff;
          width: auto;

          &:hover {
            background: $link-color;
            color: #fff;
            border: 1px solid $link-color;
          }

          svg {
            margin-right: 4px;
          }
        }

        .free-trial-btn {
          font-size: 10px;
          border: 1px solid lightgrey;
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: transparent;
          cursor: pointer;
          color: #9a9a9a;
          width: auto;

          &:hover {
          }

          svg {
            margin-right: 4px;
          }
        }

        .edit-btn {
          font-size: 12px;
          border: 1px solid #e3e3e3;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: 10px;
          background: #fff;
          cursor: pointer;
          color: #7f37be;
          width: 50px;

          &:hover {
            background: $link-color;
            color: #fff;
            border: 1px solid $link-color;
          }

          svg {
            margin-right: 4px;
          }

          &.delete-btn {
            width: auto;

            svg {
              margin: 0;
            }
          }
        }

        .list-svg {
          border: 1px solid #e3e3e3;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;

          svg {
            color: $link-color;
          }
        }

        a {
          color: $link-color;
        }

        svg {
          font-size: 14px;

          &.active {
            color: $green;
          }

          &.pending {
            color: $yellow;
          }

          &.inactive {
            color: #535353;
          }

          &.free_trial {
            color: $green;
          }

          &.cancelled {
            color: $alert-color;
          }
        }
      }
    }
  }

  &.large {
    td {
      font-size: 14px;
      padding: 10px;

      button {
        font-size: 14px;
      }
    }
  }

  &.payment-list {
    width: 100%;

    .payment-method {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      label {
        select {
          border: 1px solid #ccc;
          padding: 10px;
          background: #fff;
        }

        svg {
          right: 10px !important;
        }
      }
    }
  }
}

.list-table-modal {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    background: #efefef;

    tr {
      td {
        padding: 0 8px 0 8px;
        font-weight: 500;
        font-size: 12px;
        height: 34px;
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: #fff;
    }

    tr:nth-child(even) {
      background: #f6f6f6;
    }

    tr {
      &:hover {
        background: #e3e3e3;
      }

      &.passive {
        color: #9a9a9a !important;
      }

      td {
        padding: 0 8px 0 8px;
        font-weight: 400;
        font-size: 12px;
        position: relative;
        height: 34px;

        .screenshot-btn {
          border: 1px solid #e3e3e3;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 50px;
          border-radius: 10px;
          background: #fff;
          cursor: pointer;
          color: #7f37be;

          &:hover {
            background: $link-color;
            color: #fff;
            border: 1px solid $link-color;
          }

          svg {
            margin-right: 4px;
          }
        }

        .ad-detail-btn {
          font-size: 12px;
          border: 1px solid #e3e3e3;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: #fff;
          cursor: pointer;
          color: #7f37be;
          width: auto;

          &:hover {
            background: $link-color;
            color: #fff;
            border: 1px solid $link-color;
          }

          svg {
            margin-right: 4px;
          }
        }

        .reactivate-btn {
          font-size: 12px;
          border: 1px solid var(--primary-color);
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: var(--primary-color);
          cursor: pointer;
          color: #fff;
          width: auto;

          &:hover {
            background: $link-color;
            color: #fff;
            border: 1px solid $link-color;
          }

          svg {
            margin-right: 4px;
          }
        }

        .free-trial-btn {
          font-size: 10px;
          border: 1px solid lightgrey;
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: transparent;
          cursor: pointer;
          color: #9a9a9a;
          width: auto;

          &:hover {
          }

          svg {
            margin-right: 4px;
          }
        }

        .edit-btn {
          font-size: 12px;
          border: 1px solid #e3e3e3;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: 10px;
          background: #fff;
          cursor: pointer;
          color: #7f37be;
          width: 50px;

          &:hover {
            background: $link-color;
            color: #fff;
            border: 1px solid $link-color;
          }

          svg {
            margin-right: 4px;
          }

          &.delete-btn {
            width: auto;

            svg {
              margin: 0;
            }
          }
        }

        .list-svg {
          border: 1px solid #e3e3e3;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;

          svg {
            color: $link-color;
          }
        }

        a {
          color: $link-color;
        }

        svg {
          font-size: 14px;

          &.active {
            color: $green;
          }

          &.pending {
            color: $yellow;
          }

          &.inactive {
            color: #535353;
          }

          &.free_trial {
            color: $green;
          }

          &.cancelled {
            color: $alert-color;
          }
        }
      }
    }
  }
}

.top-ten-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    background: var(--primary-color);
    border-bottom: 1px solid #e3e3e3;
    color: #fff;

    tr {
      td {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        width: 10px;
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: $extra-light-prurpe;
    }

    tr:nth-child(even) {
      background: #fff;
    }

    tr {
      td {
        border: 1px solid #e3e3e3;
        padding: 10px;
        font-weight: 300;
        font-size: 16px;

        &:nth-child(1) {
          font-weight: 300;
          font-size: 14px;
        }

        &:nth-child(2) {
          font-weight: 400;
          font-size: 14px;
          text-align: center;

          span {
            font-size: 10px;
            margin-left: 2px;
            color: #636363;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.ads {
  .title {
    list-style: none !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding: 0;
    margin: 0;
  }

  .base {
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: 18px;
      color: #1a0dab;
      font-weight: 400;
    }

    span {
      font-size: 16px;
      color: #006621;
      margin-top: 6px;

      span {
        border: 1px solid #006621;
        padding: 1px 3px 1px 3px;
        color: #006621;
        border-radius: 4px;
        margin-right: 10px;
        font-size: 12px;
      }
    }

    p {
      font-size: 14px;
      color: #5b5b5b;
      margin-top: 10px;
      line-height: 18px;
    }
  }
}

.shadow-box {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.empty-board {
  padding: 20px;
  box-sizing: border-box;

  .icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 52px;
      color: var(--primary-color);
    }
  }

  .content {
    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }

    p {
      text-align: center;
      font-size: 16px;
      color: $content-color;
      margin: 6px 0 20px 0;
    }
  }
}

.switch-base {
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
  }

  input:checked + .switch-button {
    background-color: $green;
    justify-content: flex-start;
  }

  .switch-button {
    background: #f9f9f9;
    border-radius: 30px;
    width: 40px;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-box-shadow: inset 0 0 5px -3px rgba(0, 0, 0, 0.48);
    box-shadow: inset 0 0 5px -3px rgba(0, 0, 0, 0.48);
    border: 1px solid #e3e3e3;

    .slider {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: #fff;
    }
  }
}

.switch-time-range {
  display: flex;
  align-items: center;

  .item {
    border: 1px solid #cccccc;
    cursor: pointer;

    &:first-child {
      border-right: none;
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-left: none;
      border-radius: 0 6px 6px 0;
    }

    input {
      position: absolute;
      opacity: 0;
    }

    input:checked + .button {
      background: #e3e3e3;
    }

    .button {
      padding: 6px 10px 6px 10px;
      font-size: 12px;
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  a {
    color: $link-color;
  }

  > .page-link,
  > span {
    width: 24px;
    height: 24px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    background: #fff;
    align-items: center;
    margin: 0 2px 0 2px;
    color: $content-color;
    font-size: 12px;
    border-radius: 2px;
    cursor: pointer;

    &:hover,
    &.active {
      background: var(--primary-color);
      color: #fff;

      a {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.confirmation-box {
  display: flex;
  margin-left: 10px;

  button {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    svg {
      font-size: 10px;
    }

    &.yes {
      background: $green;
      margin-right: 4px;

      svg {
        color: #fff;
      }
    }

    &.no {
      background: $alert-color;

      svg {
        color: #fff;
      }
    }
  }
}

.more-less {
  cursor: pointer;

  .full-content {
    position: absolute;
    background: #fff;
    margin-top: -6px;
    margin-left: -6px;
    border-radius: 4px;
    padding: 6px;
    max-width: 500px;
    word-wrap: break-word;
    text-align: left;
    z-index: 40;

    &.left {
      right: 0;
    }

    &.show {
      display: block;
    }

    &.hide {
      display: none !important;
    }
  }
}

.more-option {
  position: absolute;
  right: 10px;
  top: 10px;

  .more-option-btn {
    padding: 0 4px 0 4px;
    cursor: pointer;

    svg {
      font-size: 22px;
      z-index: 40;
      position: absolute;
      right: 0;
      color: #989898;

      &.exit {
        font-size: 18px;
        padding: 4px;
        color: var(--primary-color);
        display: none;
      }
    }
  }

  .more-option-content {
    display: none;
    position: absolute;
    top: 0;
    right: 24px;
    width: 120px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px 0 4px 0;
    -webkit-box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.75);
    z-index: 10;
    overflow: hidden;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      position: relative;
      cursor: pointer;

      li {
        box-sizing: border-box;
        font-size: 14px;
        padding: 0;
        border-bottom: 1px solid #e3e3e3;
        width: 100%;

        &:last-child {
          border: none;
        }

        &:hover {
          background: #f3f3f3;
        }

        a,
        span,
        button {
          border: none;
          background: transparent;
          margin: 0;
          width: 100%;
          display: inline-block;
          color: #000;
          text-align: left;
          font-size: 12px;
          font-weight: 400;
          padding: 8px 10px 8px 10px;
        }

        &.passive {
          font-size: 12px;

          p {
            font-size: 10px !important;
          }

          &:hover {
            background: #fff;
          }
        }
      }
    }
  }

  &.show {
    .more-option-btn {
      .more {
        display: none;
      }

      .exit {
        display: block;
      }
    }

    .more-option-content {
      display: block;
    }
  }
}

.sortableHolder {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: #000 !important;

  &.passive {
    color: #ccc !important;
  }
}
