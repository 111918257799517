html,
.theme-us {
  --primary-color: rgb(24, 134, 156);
  --primary-color-rgb: 24, 134, 156;
  header {
    background-color: rgb(24, 134, 156) !important;
  }
}
.theme-uk {
  --primary-color: rgb(0, 36, 125);
  --primary-color-rgb: 0, 36, 125;
  header {
    background-color: rgb(0, 36, 125) !important;
  }
}
$primary-color: rgb(24, 134, 156);
$dark-purple: #471077;
$border-purple: #0da9c7;
$light-purple: #a4f0ff;
$extra-light-prurpe: #fbf8fe;
$content-color: #3a3a3a;
$green: #06b41e;
$light: #e3e3e3;
$grey: #535353;
$dark: #535353;
$yellow: #fed326;
$grey75: #757575;
$grey9a: #9a9a9a;
$alert-color: #ff2c2c;
$desktop-color: #ff7200;
$mobile-color: #34a6f5;
$tablet-color: #26ca7b;
$link-color: #1640aa;
