.tab-menu {
  height: 118px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background: red;

    li {
      font-size: 12px;
      height: calc(100% / 3);
      background-color: #efefef;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      text-align: left;
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;
      color: #7b7b7b;

      svg {
        display: none;
      }

      &:hover {
        svg {
          display: block;
        }
      }

      &.react-tabs__tab--selected {
        background: #fff;
        color: #000;

        svg {
          display: block;
          color: var(--primary-color);
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .tab-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    width: calc(100% / 3);
    height: 118px;
    cursor: pointer;
    border-right: 1px solid #e3e3e3;

    &:last-child {
      border-right: none;
    }

    &:hover {
      background: #f9f9f9;
    }

    a {
      font-size: 12px;
      padding-bottom: 6px;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: #7d8388;
    }

    p {
      font-size: 28px;
      font-weight: 300;
    }
  }

  .list-table {
    border-left: 1px solid #efefef;

    thead {
      tr {
        td {
          padding: 2px 8px !important;
        }
      }
    }

    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }

        td {
          padding: 7px !important;
          height: auto;
        }
      }
    }
  }
}
