.notifications {
  border-left: 1px solid $border-purple;
  position: relative;
  cursor: pointer;

  .notification-button {
    .badge {
      position: absolute;
      border-radius: 4px;
      background: $yellow;
      font-size: 8px;
      line-height: 12px;
      padding: 2px;
      top: 8px;
      right: 14px;
    }

    svg {
      &.passive {
        color: $light-purple;
      }

      &.active {
      }
    }
  }

  .notification-list {
    position: absolute;
    background: #fff;
    top: 50px;
    width: 300px;
    right: -130px;
    z-index: 500;

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }

    .title {
      padding: 10px;
      border-bottom: 1px solid #e3e3e3;

      span,
      svg {
        color: $grey75;
      }
    }

    .scroll-base {
      overflow-y: auto;
      max-height: 280px;

      .item {
        padding: 10px 10px 10px 10px;
        border-bottom: 1px solid #e3e3e3;
        background: $extra-light-prurpe;
        cursor: pointer;

        &:hover {
          background: #f3f3f3;
        }

        &.read {
          background: #fff;

          span {
            color: lightslategrey;
          }

          &:hover {
            background: #f3f3f3;
          }
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #e3e3e3;

          svg {
            color: #fff;
          }

          &.red {
            background: #ff3737;
          }

          &.yellow {
            background: #dfd819;
          }

          &.green {
            background: #33e243;
          }
        }

        .content {
          width: 260px;
          box-sizing: border-box;
        }
      }
    }

    .action {
      padding: 10px;
    }
  }
}
