footer {
  height: 20px;
  background: transparent;
  margin-top: 40px;
  padding: 10px;

  span {
    font-size: 10px;
    color: $extra-light-prurpe;
  }
}
