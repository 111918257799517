.close {
  height: 0;
  overflow: hidden;
}

.open {
  height: 400px;
}

.ppc-detail {
  .results {
    width: 1000px;
  }
}

.side-filter {
  width: 180px;

  .filter {
    .section {
      padding: 10px;
      border-bottom: 1px solid #e3e3e3;

      h2 {
        font-size: 12px;
        font-weight: 700;
        padding: 6px 0 10px 0;
      }

      label {
        margin: 4px 0 4px 0;
      }
    }
  }
}

.date-filter {
  background: transparent;

  .date-filter__label {
    padding: 6px 40px 6px 0;
    height: auto;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    background: none;
    transition: all 1s;
    font-size: 14px;
    overflow: hidden;
    color: #000;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 14px;

    &:after {
      width: 9px;
      height: auto;
      content: ' ';
      display: block;
      transform: rotate(-90deg);
      float: right;
      margin: 10px 0 0 10px;
    }
  }

  .date-filter__container {
    z-index: 9;
    position: absolute;
    right: 0;
    top: 30px;
    background-color: #fff;
    padding: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3),
      0 4px 8px 3px rgba(0, 0, 0, 0.15);
    width: 600px;

    .calendar {
      border-bottom: 1px solid #e3e3e3;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      .date-filter__container__special-select {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 155px;

        .time-picker {
          .date-filter__container__special-select__title {
            padding: 10px;
            border-bottom: 1px solid #e3e3e3;
            width: 158px;
            box-sizing: border-box;

            h2 {
              font-size: 12px;
              font-weight: 400;
            }
          }

          ul {
            margin: 0;
            padding: 0;
            height: 150px;
            overflow-x: auto;
            margin-top: 10px;

            li {
              list-style: none;
              line-height: 23px;
              border-bottom: 1px solid #f9f9f9;

              button {
                border: none;
                font-size: 12px;
                background: transparent;
                width: 100%;
                text-align: left;
                cursor: pointer;
                padding: 8px 6px 8px 10px;

                &:focus {
                  outline: none;
                  background: $extra-light-prurpe;
                  color: var(--primary-color);
                }
              }

              &.active:before {
                content: ' ';
                display: block;
                float: left;
                margin: 8px 0 0 -14px;
                width: 9px;
                height: 8px;
              }

              &:hover {
                background: $extra-light-prurpe;
                cursor: pointer;
              }
            }
          }

          .date-filter__controls {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            border-top: 1px solid #ccc;
            width: 100%;
            margin-top: 10px;

            button {
              min-width: 48% !important;
              cursor: pointer !important;
              padding: 6px;
              font-size: 14px;

              &:nth-child(2) {
              }

              &:nth-child(1) {
              }
            }
          }
        }
      }

      .DayPicker {
        border-right: 1px solid #e3e3e3;

        div {
          outline: none;
        }

        .DayPicker-wrapper {
          position: relative;
          box-sizing: border-box;
          height: 246px;

          .DayPicker-NavBar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            position: relative;

            .DayPicker-Months {
              display: flex !important;
              justify-content: center !important;
            }

            .DayPicker-NavButton {
              &.DayPicker-NavButton--prev {
                right: 0;
                left: auto;
                top: 10px;
                background-size: 6px;
                outline: none;
              }

              &.DayPicker-NavButton--next {
                right: 4px;
                top: 10px;
                background-size: 6px;
                transform: rotate(0deg) !important;
                outline: none;
              }
            }
          }

          .DayPicker-Weekdays {
            .DayPicker-WeekdaysRow {
              .DayPicker-Weekday {
                abbr {
                  font-size: 10px;
                  color: #6b6b6b;
                }
              }
            }
          }

          .DayPicker-Months {
            width: auto;
            display: flex;
            justify-content: flex-start;

            .DayPicker-Month {
              margin: 0 !important;

              &:nth-child(2) {
                margin-left: 20px !important;
              }

              .DayPicker-Caption {
                margin: 0;
                border-bottom: 1px solid #e3e3e3;
                padding: 10px;
                color: $grey75;

                div {
                  font-size: 12px;
                  line-height: 14px;
                  font-weight: 400;
                }
              }

              .DayPicker-Body {
                .DayPicker-Day {
                  padding: 0;
                  font-size: 12px;
                  width: 30px;
                  height: 30px;

                  &.DayPicker-Day--today {
                    color: var(--primary-color);
                    outline: none;

                    &:hover {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #fff !important;
  background-color: var(--primary-color);
  outline: none;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 99px !important;
  outline: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background: $light-purple;
}

.DayPicker-Day--disabled {
  outline: none;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
