h1,
h2,
h3,
p,
span,
a {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

hr {
  border: 1px solid #e3e3e3;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
  &:focus {
    outline: none;
  }
}

button {
  &:focus {
    outline: none;
  }
}

.c-mobile {
  color: $mobile-color;
}

.c-desktop {
  color: $desktop-color;
}

.c-tablet {
  color: $tablet-color;
}
.c-link {
  color: $link-color;
}

.modal-table {
  width: 100%;

  thead {
    background: #e3e3e3;

    tr {
      td {
        padding: 8px;
        font-weight: 500;
        border: 1px solid #cccccc;
        font-size: 14px;
        width: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 8px;
        font-weight: 300;
        border: 1px solid #cccccc;
      }
    }
  }
}

.border-box {
  box-sizing: border-box;
}

// FONT COLORS

.f-white {
  color: #fff;
}

.f-grey {
  color: $grey;
}

.f-light-purple {
  color: $light-purple;
}

.f-passive {
  color: #9a9a9a !important;
}

.f-primary {
  color: var(--primary-color);
}

.f-red {
  color: $alert-color;
}

// TEXT ALIGN

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.text-underline {
  text-decoration: underline;
}

// FONT SIZE
.sz-10 {
  font-size: 10px !important;
}

.sz-12 {
  font-size: 12px !important;
}

.sz-14 {
  font-size: 14px;
}

.sz-15 {
  font-size: 15px;
}

.sz-16 {
  font-size: 16px;
}

.sz-18 {
  font-size: 18px;
}

.sz-20 {
  font-size: 20px;
}

.sz-22 {
  font-size: 22px;
}

.sz-24 {
  font-size: 24px;
}

.sz-26 {
  font-size: 26px;
}

.sz-28 {
  font-size: 28px;
}

.sz-30 {
  font-size: 30px;
}

.sz-32 {
  font-size: 32px;
}

.sz-34 {
  font-size: 34px;
}

.sz-36 {
  font-size: 36px;
}

.sz-38 {
  font-size: 38px;
}

.sz-40 {
  font-size: 40px;
}

.sz-42 {
  font-size: 42px;
}

.sz-72 {
  font-size: 72px !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

// TEXT POSITION
.t-center {
  text-align: center;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.p-relative {
  position: relative;
}

.block {
  display: block;
}

// FLEX BOXES
.flex {
  display: flex;
}

.horizon {
  display: flex;
  box-sizing: border-box;
  flex-direction: row !important;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end !important;
  }

  &.center {
    justify-content: center;
  }

  &.around {
    justify-content: space-around;
  }

  &.between {
    justify-content: space-between;
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.middle {
    align-items: center !important;
  }

  &.stretch {
    align-items: stretch;
  }
}

.vertical {
  display: flex;
  flex-direction: column;

  &.left {
    align-items: flex-start !important;
  }

  &.right {
    align-items: flex-end;
  }

  &.center {
    align-items: center;
  }

  &.top {
    justify-content: flex-start;
  }

  &.middle {
    justify-content: center;
  }

  &.bottom {
    justify-content: flex-end;
  }

  &.around {
    justify-content: space-around;
  }

  &.between {
    justify-content: space-between;
  }
}

.ivy {
  display: flex;
  flex-wrap: wrap;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.around {
    justify-content: space-around;
  }

  &.between {
    justify-content: space-between;
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.middle {
    align-items: center;
  }
}

// DROPDOWN MENU
.dropper {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 700ms;
  -webkit-box-shadow: 1px 1px 5px -2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 5px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px -2px rgba(0, 0, 0, 0.5);

  &.closed {
    margin: 0;
  }

  &.opened {
    .dropper-content {
      display: block !important;
    }
  }

  .dropper-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $light;
    cursor: pointer;

    p {
      font-size: 18px;
      font-weight: 400;
      padding: 10px;
    }

    i {
      font-size: 14px;
      padding: 10px;
      font-weight: 500;
      color: $content-color;
    }
  }

  .dropper-content {
    display: none;
    padding: 10px;
    transition: all 0.2s ease-out;

    p {
      font-size: 14px;
      line-height: 24px;
      color: $content-color;
    }
  }
}

// SLIDE BOX
.slidebox {
  width: 100%;
  position: relative;

  .gallery {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .item {
      position: absolute;
      width: 100%;
      overflow: hidden;
      z-index: 80;
      top: 0;

      &.first {
        position: relative;
        width: 100%;
        overflow: hidden;
        z-index: 90;
      }

      img {
        width: 100%;
      }
    }
  }

  .prev {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    cursor: pointer;
    z-index: 99;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .next {
    display: flex;
    position: absolute;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    cursor: pointer;
    z-index: 99;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  i {
    font-size: 54px;
    color: white;
    text-shadow: 0 0 6px rgba(150, 150, 150, 1);
  }
}

// CARD

.card {
  border-style: solid;
  border-width: 1px;
  border-color: #e3e3e3;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .card-title {
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #efefef;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 10px 14px 10px;
    background: #fdfdfd;
    border-radius: 6px 6px 0 0;

    h3 {
      font-size: 18px;
      font-weight: 400;
      display: inline;
    }

    p {
      font-size: 20px;
    }

    span {
      font-size: 12px;
      color: $content-color;
      padding-top: 6px;
    }
  }

  .card-body {
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    p {
      font-size: 14px;
    }
  }

  .card-action {
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 8px;
  }

  .card-image {
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.see-more {
  font-size: 12px;
  color: #9a9a9a;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;

  &.blue {
    color: $link-color;
    text-decoration: underline;
  }
}

// FORM ELEMENTS

label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}

.checkbox-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 25px;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0 !important;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid #e3e3e3;
    border-radius: 6px 0 6px 0;

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input {
    &:checked ~ .checkmark:after {
      display: block;
    }

    &:checked ~ .checkmark {
      background-color: var(--primary-color);
    }
  }
}

.checkbox-label .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// NAVIGATION MENU

.navigation {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    li {
      list-style-type: none;
      padding: 14px 18px 14px 18px;
      cursor: pointer;

      a {
        font-size: 14px;
        color: #3a3a3a;
      }
    }
  }

  .hover-line {
    position: absolute;
    height: 4px;
    background: var(--primary-color);
    bottom: 0;
  }
}

// CONTEXTUAL MENU

.contextual-menu {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  right: 10px;

  .contextual-button {
    cursor: pointer;
    width: 30px;
    text-align: right;

    i {
      font-size: 22px;
      color: #7a7a7a;
    }
  }

  .contextual-dropper {
    background: #fff;
    display: flex;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 10px 0 10px 0;
    -moz-border-radius: 10px 0 10px 0;
    border-radius: 10px 0 10px 0;
    overflow: hidden;
    -webkit-box-shadow: 1px 1px 5px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 5px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 5px -2px rgba(0, 0, 0, 0.5);

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        padding: 10px 10px 10px 10px;
        border-bottom: 1px solid #e3e3e3;
        font-size: 14px;
        cursor: pointer;

        i {
          font-size: 18px;
          margin-right: 10px;
        }

        &:last-child {
          border: none;
        }

        &:hover {
          background: var(--primary-color);
          color: #fff;
        }
      }
    }
  }
}

// ALERT

.alert-box {
  position: absolute;
  padding: 10px 20px;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  animation: smooth-show 2s;
  &.form-alert {
    padding: 6px !important;
    border-radius: 4px;
    margin-top: 4px;
    &.error {
      p {
        font-size: 12px;
      }
    }
  }
  &.success {
    background: #fff;
    border: 1px solid #399d11;
    border-top: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      color: #399d11;
      font-size: 14px;
      font-weight: 600;
      padding: 6px;
    }
    svg {
      color: #399d11;
    }
    .btn-link {
      font-size: 16px;
      width: 60px;
      margin: 0 10px 0 10px;
      background: #fff;
      height: 30px;
      padding: 4px !important;
      color: #399d11;
      border: 1px dashed #399d11;
      border-radius: 4px;
    }
  }
  &.warn {
    background: #fff;
    border: 1px solid #e57d21;
    border-top: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      color: #e57d21;
      font-size: 14px;
      font-weight: 600;
      padding: 6px;
    }
    svg {
      color: #e57d21;
    }
  }
  &.error {
    background: #fff;
    border: 1px solid #dc556c;
    border-top: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon {
      background: #dc556c;
      padding: 6px;
      svg {
        position: relative;
        color: #fff;
      }
    }
    p {
      color: #dc556c;
      font-size: 14px;
      font-weight: 600;
      padding: 6px;
    }
  }
  button {
    border: none;
    background: transparent;
    height: 40px;
    width: 40px;
    svg {
      color: #9a9a9a;
      font-size: 22px;
    }
  }
  .alert-close {
    cursor: pointer;

    i {
      font-size: 24px;
      color: #fff;
    }
  }

  @keyframes smooth-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.note {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  background: #d8d8d8;

  .icon {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 10px;

    svg {
      font-size: 20px;
      color: #9a9a9a;
    }
  }

  span {
    font-size: 12px;
    line-height: 16px;
  }
}

// TAB

.tab-base {
  width: 100%;

  .tab-menu {
    width: 100%;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: inline-block;

    .tab-link {
      display: inline-block;
      list-style-type: none;
      padding: 10px 16px 10px 16px;
      cursor: pointer;

      &.active-tab {
        border-bottom: 4px solid var(--primary-color);
      }
    }
  }

  #tab-content {
    width: 100%;
    position: relative;

    .tab-item {
      display: none;
      width: 100%;
      padding: 10px;
    }
  }
}

// MARGIN SERIES

// Margin

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.m-50 {
  margin: 50px;
}

.m-60 {
  margin: 60px;
}

.m-70 {
  margin: 70px;
}

.m-80 {
  margin: 80px;
}

.m-90 {
  margin: 90px;
}

.m-100 {
  margin: 100px;
}

// Margin Top =
.mt-6 {
  margin-top: 6px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-11 {
  margin-top: 11px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-13 {
  margin-top: 13px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

// Margin Bottom =
.mb-6 {
  margin-bottom: 6px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

// Margin Left =
.ml-0 {
  margin-left: 0 !important;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-100 {
  margin-left: 100px;
}

// Margin Right =
.mr-0 {
  margin-right: 0;
}

.mr-6 {
  margin-right: 6px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

// PADDING SERIES

//Padding =
.p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: 4px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.p-50 {
  padding: 50px;
}

.p-60 {
  padding: 60px;
}

.p-70 {
  padding: 70px;
}

.p-80 {
  padding: 80px;
}

.p-90 {
  padding: 90px;
}

.p-100 {
  padding: 100px;
}

//Padding Top =
.pt-2 {
  padding-top: 10px;
}

.pt-4 {
  padding-top: 10px;
}

.pt-6 {
  padding-top: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

//Padding Bottom =
.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

//Padding Left =
.pl-10 {
  padding-left: 10px !important;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

//Padding Right =
.pr-10 {
  padding-right: 10px !important;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

// BORDER SERIES
// Lıght Border #e3e3e3 =
.light-b-1 {
  border: 1px solid $light;
}

.light-bb-1 {
  border-bottom: 1px solid $light;
}

.light-bt-1 {
  border-top: 1px solid $light;
}

.light-bl-1 {
  border-left: 1px solid $light;
}

.light-br-1 {
  border-right: 1px solid $light;
}

// Lıght Border #4d4d4d =
.dark-b-1 {
  border: 1px solid $dark;
}

.dark-bb-1 {
  border-bottom: 1px solid $dark;
}

.dark-bt-1 {
  border-top: 1px solid $dark;
}

.dark-bl-1 {
  border-left: 1px solid $dark;
}

.dark-br-1 {
  border-right: 1px solid $dark;
}

// PERCENT SERIES
.per-auto {
  width: auto !important;
}

.per-10 {
  width: 10% !important;
}

.per-11 {
  width: 11% !important;
}

.per-12 {
  width: 12% !important;
}

.per-13 {
  width: 13% !important;
}

.per-14 {
  width: 14% !important;
}

.per-15 {
  width: 15% !important;
}

.per-16 {
  width: 16% !important;
}

.per-17 {
  width: 17% !important;
}

.per-18 {
  width: 18% !important;
}

.per-19 {
  width: 19% !important;
}

.per-20 {
  width: 20% !important;
}

.per-21 {
  width: 21% !important;
}

.per-22 {
  width: 22% !important;
}

.per-23 {
  width: 23% !important;
}

.per-24 {
  width: 24% !important;
}

.per-25 {
  width: 25% !important;
}

.per-26 {
  width: 26% !important;
}

.per-27 {
  width: 27% !important;
}

.per-28 {
  width: 28% !important;
}

.per-29 {
  width: 29% !important;
}

.per-30 {
  width: 30% !important;
}

.per-31 {
  width: 31% !important;
}

.per-32 {
  width: 32% !important;
}

.per-33 {
  width: 33% !important;
}

.per-35 {
  width: 35% !important;
}

.per-36 {
  width: 36% !important;
}

.per-37 {
  width: 37% !important;
}

.per-38 {
  width: 38% !important;
}

.per-39 {
  width: 39% !important;
}

.per-40 {
  width: 40% !important;
}

.per-41 {
  width: 41% !important;
}

.per-42 {
  width: 42% !important;
}

.per-43 {
  width: 43% !important;
}

.per-44 {
  width: 44% !important;
}

.per-45 {
  width: 45% !important;
}

.per-46 {
  width: 46% !important;
}

.per-47 {
  width: 47% !important;
}

.per-48 {
  width: 48% !important;
}

.per-49 {
  width: 49% !important;
}

.per-50 {
  width: 50% !important;
}

.per-51 {
  width: 51% !important;
}

.per-52 {
  width: 52% !important;
}

.per-53 {
  width: 53% !important;
}

.per-54 {
  width: 54% !important;
}

.per-55 {
  width: 55% !important;
}

.per-56 {
  width: 56% !important;
}

.per-57 {
  width: 57% !important;
}

.per-58 {
  width: 58% !important;
}

.per-59 {
  width: 59% !important;
}

.per-60 {
  width: 60% !important;
}

.per-61 {
  width: 61% !important;
}

.per-62 {
  width: 62% !important;
}

.per-63 {
  width: 63% !important;
}

.per-64 {
  width: 64% !important;
}

.per-65 {
  width: 65% !important;
}

.per-66 {
  width: 66% !important;
}

.per-67 {
  width: 67% !important;
}

.per-68 {
  width: 68% !important;
}

.per-69 {
  width: 69% !important;
}

.per-70 {
  width: 70% !important;
}

.per-71 {
  width: 71% !important;
}

.per-72 {
  width: 72% !important;
}

.per-73 {
  width: 73% !important;
}

.per-74 {
  width: 74% !important;
}

.per-75 {
  width: 75% !important;
}

.per-76 {
  width: 76% !important;
}

.per-77 {
  width: 77% !important;
}

.per-78 {
  width: 78% !important;
}

.per-79 {
  width: 79% !important;
}

.per-80 {
  width: 80% !important;
}

.per-81 {
  width: 81% !important;
}

.per-82 {
  width: 82% !important;
}

.per-83 {
  width: 83% !important;
}

.per-84 {
  width: 84% !important;
}

.per-85 {
  width: 85% !important;
}

.per-86 {
  width: 86% !important;
}

.per-87 {
  width: 87% !important;
}

.per-88 {
  width: 88% !important;
}

.per-89 {
  width: 89% !important;
}

.per-90 {
  width: 90% !important;
}

.per-91 {
  width: 91% !important;
}

.per-92 {
  width: 92% !important;
}

.per-93 {
  width: 93% !important;
}

.per-94 {
  width: 94% !important;
}

.per-95 {
  width: 95% !important;
}

.per-96 {
  width: 96% !important;
}

.per-97 {
  width: 97% !important;
}

.per-98 {
  width: 98% !important;
}

.per-99 {
  width: 99% !important;
}

.per-100 {
  width: 100% !important;
}

.d-hide {
  display: none;
}

.m-hide {
  display: flex;
}

@media (max-width: 986px) {
  .m-hide {
    display: none !important;
  }
  .d-hide {
    display: block !important;
  }
  .m-h-100 {
    height: 100%;
  }
  .m-h-auto {
    height: auto;
  }
  .m-horizon {
    display: flex;
    box-sizing: border-box;

    &.m-left {
      justify-content: flex-start;
    }

    &.m-right {
      justify-content: flex-end;
    }

    &.m-center {
      justify-content: center;
    }

    &.m-around {
      justify-content: space-around;
    }

    &.m-between {
      justify-content: space-between;
    }

    &.m-top {
      align-items: flex-start;
    }

    &.m-bottom {
      align-items: flex-end;
    }

    &.m-middle {
      align-items: center;
    }

    &.m-stretch {
      align-items: stretch;
    }
  }

  .m-vertical {
    display: flex;
    flex-direction: column;
    transition: all 5s ease;

    &.m-left {
      align-items: flex-start;
    }

    &.m-right {
      align-items: flex-end;
    }

    &.m-center {
      align-items: center;
    }

    &.m-top {
      justify-content: flex-start;
    }

    &.m-middle {
      justify-content: center;
    }

    &.m-bottom {
      justify-content: flex-end;
    }

    &.m-around {
      justify-content: space-around;
    }

    &.m-between {
      justify-content: space-between;
    }

    &.m-reverse {
      flex-direction: column-reverse !important;
    }
  }
  .m-order-1 {
    order: 1;
  }
  .m-order-2 {
    order: 2;
  }
  .m-order-3 {
    order: 3;
  }
  .m-order-4 {
    order: 4;
  }
  .m-per-10 {
    width: 10% !important;
  }
  .m-per-20 {
    width: 20% !important;
  }
  .m-per-30 {
    width: 30% !important;
  }
  .m-per-40 {
    width: 40% !important;
  }
  .m-per-50 {
    width: 50% !important;
  }
  .m-per-60 {
    width: 60% !important;
  }
  .m-per-70 {
    width: 70% !important;
  }
  .m-per-08 {
    width: 80% !important;
  }
  .m-per-90 {
    width: 90% !important;
  }
  .m-per-100 {
    width: 100% !important;
  }

  .m-mt-10 {
    margin-top: 10px;
  }
  .m-mt-20 {
    margin-top: 20px;
  }
  .m-mt-30 {
    margin-top: 30px;
  }
  .m-mt-40 {
    margin-top: 40px;
  }
  .m-mt-50 {
    margin-top: 50px;
  }
  .m-mt-60 {
    margin-top: 60px;
  }
  .m-mt-70 {
    margin-top: 70px;
  }
  .m-mt-80 {
    margin-top: 80px;
  }
  .m-mt-90 {
    margin-top: 90px;
  }
  .m-mt-100 {
    margin-top: 100px;
  }

  // Margin Bottom =
  .m-mb-10 {
    margin-bottom: 10px;
  }

  .m-mb-20 {
    margin-bottom: 20px;
  }

  .m-mb-30 {
    margin-bottom: 30px;
  }

  .m-mb-40 {
    margin-bottom: 40px;
  }

  .m-mb-50 {
    margin-bottom: 50px;
  }

  .m-mb-60 {
    margin-bottom: 60px;
  }

  .m-mb-70 {
    margin-bottom: 70px;
  }

  .m-mb-80 {
    margin-bottom: 80px;
  }

  .m-mb-90 {
    margin-bottom: 90px;
  }

  .m-mb-100 {
    margin-bottom: 100px;
  }

  // Margin Left =
  .m-ml-10 {
    margin-left: 10px;
  }

  .m-ml-20 {
    margin-left: 20px;
  }

  .m-ml-30 {
    margin-left: 30px;
  }

  .m-ml-40 {
    margin-left: 40px;
  }

  .m-ml-50 {
    margin-left: 50px;
  }

  .m-ml-60 {
    margin-left: 60px;
  }

  .m-ml-70 {
    margin-left: 70px;
  }

  .m-ml-80 {
    margin-left: 80px;
  }

  .m-ml-90 {
    margin-left: 90px;
  }

  .m-ml-100 {
    margin-left: 100px;
  }

  // Margin Right =
  .m-mr-10 {
    margin-right: 10px !important;
  }

  .m-mr-20 {
    margin-right: 20px !important;
  }

  .m-mr-30 {
    margin-right: 30px !important;
  }

  .m-mr-40 {
    margin-right: 40px !important;
  }

  .m-mr-50 {
    margin-right: 50px !important;
  }

  .m-mr-60 {
    margin-right: 60px !important;
  }

  .m-mr-70 {
    margin-right: 70px !important;
  }

  .m-mr-80 {
    margin-right: 80px !important;
  }

  .m-mr-90 {
    margin-right: 90px !important;
  }

  .m-mr-100 {
    margin-right: 100px !important;
  }

  .m-p-0 {
    padding: 0 !important;
  }

  .m-p-10 {
    padding: 10px !important;
  }

  .m-p-20 {
    padding: 20px !important;
  }

  .m-p-30 {
    padding: 30px !important;
  }

  .m-p-40 {
    padding: 40px !important;
  }

  .m-p-50 {
    padding: 50px !important;
  }

  .m-p-60 {
    padding: 60px !important;
  }

  .m-p-70 {
    padding: 70px !important;
  }

  .m-p-80 {
    padding: 80px !important;
  }

  .m-p-90 {
    padding: 90px !important;
  }

  .m-p-100 {
    padding: 100px !important;
  }

  //Padding Top =
  .m-pt-10 {
    padding-top: 10px !important;
  }

  .m-pt-20 {
    padding-top: 20px !important;
  }

  .m-pt-30 {
    padding-top: 30px !important;
  }

  .m-pt-40 {
    padding-top: 40px !important;
  }

  .m-pt-50 {
    padding-top: 50px !important;
  }

  .m-pt-60 {
    padding-top: 60px !important;
  }

  .m-pt-70 {
    padding-top: 70px !important;
  }

  .m-pt-80 {
    padding-top: 80px !important;
  }

  .m-pt-90 {
    padding-top: 90px !important;
  }

  .m-pt-100 {
    padding-top: 100px !important;
  }

  //Padding Bottom =
  .m-pb-10 {
    padding-bottom: 10px;
  }

  .m-pb-20 {
    padding-bottom: 20px;
  }

  .m-pb-30 {
    padding-bottom: 30px;
  }

  .m-pb-40 {
    padding-bottom: 40px;
  }

  .m-pb-50 {
    padding-bottom: 50px;
  }

  .m-pb-60 {
    padding-bottom: 60px;
  }

  .m-pb-70 {
    padding-bottom: 70px;
  }

  .m-pb-80 {
    padding-bottom: 80px;
  }

  .m-pb-90 {
    padding-bottom: 90px;
  }

  .m-pb-100 {
    padding-bottom: 100px;
  }

  //Padding Left =
  .m-pl-10 {
    padding-left: 10px;
  }

  .m-pl-20 {
    padding-left: 20px;
  }

  .m-pl-30 {
    padding-left: 30px;
  }

  .m-pl-40 {
    padding-left: 40px;
  }

  .m-pl-50 {
    padding-left: 50px;
  }

  .m-pl-60 {
    padding-left: 60px;
  }

  .m-pl-70 {
    padding-left: 70px;
  }

  .m-pl-80 {
    padding-left: 80px;
  }

  .m-pl-90 {
    padding-left: 90px;
  }

  .m-pl-100 {
    padding-left: 100px;
  }

  //Padding Right =
  .m-pr-10 {
    padding-right: 10px;
  }

  .m-pr-20 {
    padding-right: 20px;
  }

  .m-pr-30 {
    padding-right: 30px;
  }

  .m-pr-40 {
    padding-right: 40px;
  }

  .m-pr-50 {
    padding-right: 50px;
  }

  .m-pr-60 {
    padding-right: 60px;
  }

  .m-pr-70 {
    padding-right: 70px;
  }

  .m-pr-80 {
    padding-right: 80px;
  }

  .m-pr-90 {
    padding-right: 90px;
  }

  .m-pr-100 {
    padding-right: 100px;
  }
  .m-text-center {
    text-align: center !important;
  }
  .m-text-left {
    text-align: left;
  }
  .m-text-right {
    text-align: right;
  }
}

// SCROLL BAR

#scrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#scrollBar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#scrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
}
